<template>
  <svg class="ranking-stripes" data-name="ranking-stripes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 579.915 566.929"><polygon points="283.465 0 135.093 0 0 566.929 148.372 566.927 283.465 0" :style="firstColor"/><polygon points="431.804 0 283.432 0 148.339 566.927 296.712 566.925 431.804 0" :style="secondColor"/><polygon points="579.915 0 431.543 0 296.451 566.925 444.824 566.923 579.915 0" :style="thirdColor"/></svg>
  <!--svg class="ranking-stripes" data-name="ranking-stripes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 869.648 566.929"><polygon points="425.197 0 202.639 0 0 566.929 222.558 566.927 425.197 0" style="fill:#4d4d4d"/><polygon points="647.536 0 424.978 0 222.339 566.927 444.898 566.925 647.536 0" style="fill:#9a9a9a"/><polygon points="869.648 0 647.089 0 444.451 566.925 667.01 566.923 869.648 0" style="fill:#d5d5d5"/></svg-->
</template>

<script>
export default {
  name: "RankElementStripes",
  props: ['colors'],
  computed: {
    firstColor() {
      return {
        fill: this.colors[0]
      }
    },
    secondColor() {
      return {
        fill: this.colors[1]
      }
    },
    thirdColor() {
      return {
        fill: this.colors[2]
      }
    }
  }
}
</script>

<style scoped>

</style>
