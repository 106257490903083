<template>
  <div class="rank-footer">
    <img v-if="image" :src="image" class="bg">
    <img v-else src="footer">
    <div class="floating-container" ref="container">
      <div class="floating-inner" ref="inner">
        <template v-if="currentView == 'sponsors'">
          <img src="../assets/brs.png" class="sponsor"/>
          <img src="../assets/saufhittn.png" class="sponsor"/>
          <div v-if="eventId !== -1" style="display: flex">
            <div v-bind:key="sponsor.id"
                 v-for="sponsor in sponsors"
            >
              <img :src="getSponsorImage(sponsor.image)" class="sponsor"/>
            </div>
          </div>
        </template>
        <template v-if="currentView == 'info'">
          <div v-if="eventId !== -1" style="display: flex">
            <div class="info-text"
                  v-bind:key="footer.id"
                  v-for="footer in footers"
            >
              {{ footer.text }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import restService from '../components/services/rest'


export default {
  name: "RankFooter",
  props: ['eventId', 'image'],
  data() {
    return {
      floatUpdater: undefined,
      currentView: 'sponsors',
      footers: [],
      sponsors: [],
    }
  },
  async mounted() {
    await this.gatherFooter()
    await this.gatherSponsors()
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await this.gatherFooter()
      await this.gatherSponsors()
      await this.floatOnce()
      this.switchView()
    }
  },
  methods: {
    switchView() {
      if (this.currentView === 'sponsors') this.currentView = 'info'
      else if (this.currentView === 'info') {
        this.currentView = 'sponsors'
      }
    },
    async floatOnce() {

      const SPEED_MULTIPLIER = 30

      let context = this
      return new Promise(resolve => {
        const container = context.$refs.container
        const inner = context.$refs.inner

        // eslint-disable-next-line no-undef
        $(container).css('left', '1920px')

        setTimeout(function () {
          // eslint-disable-next-line no-undef
          console.log((-$(inner).width())+'px')
          // eslint-disable-next-line no-undef
          $(container).animate({"left": (-$(inner).width())+'px'}, {
            // eslint-disable-next-line no-undef
            duration: ($(inner).width() + 1920) * SPEED_MULTIPLIER,
            easing: "linear",
            complete: resolve
          })
        }, 500)
      })
    },
    async gatherFooter() {
      if (this.eventId === -1) {
        return
      }
      this.footers = []
      this.footers = await restService.loadFooters(this.eventId)

      this.footers.sort((a, b) => {
        return a.sorting - b.sorting
      })
    },
    async gatherSponsors() {
      if (this.eventId === -1) {
        return
      }
      this.sponsors = []
      this.sponsors = await restService.loadEventSponsors(this.eventId)

      this.sponsors.sort((a, b) => {
        return a.sorting - b.sorting
      })
    },
    getSponsorImage(url) {
      return process.env.VUE_APP_BACKEND_URL + '/images/sponsors/' + url
    },
  },
  unmounted() {
    clearInterval(this.floatUpdater)
  }
}
</script>

<style scoped lang="scss">
.rank-footer {
  width: 100%;
  height: 120px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffffff;
  position: absolute;
  bottom: 0px;
  border-top: 3px solid #614a2b;
  box-sizing: border-box;
  box-shadow: 0px -8px 20px 0px #614a2b;
  color: white;
}

.floating-container {
  height: 120px;
  position: absolute;
  left: 1920px;
}

.footer {
  background-image: url("../assets/footer.png");
}

.floating-inner {
  display: flex;
  gap: 50px;
  margin-top: 20px;
  height: 80px;
  img {
    height: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
}

.info-text {
  margin-top: 20px;
  font-size: 40px;
  line-height: 40px;
  text-wrap: none;
  word-wrap: break-word;
  word-break: unset;
  white-space: nowrap;
  text-shadow: 2px 2px 6px #000000;
  margin-left: 50px;
  margin-right: 50px;
}

.sponsor {
  background: #ffffff75;
  border-radius: 15px;
  box-shadow: 0px 0px 13px 9px #ffffff75;
}

.bg {
  min-width: 1024px;
  width: 100%;
  position: fixed;
  height: 120px;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  img.bg {
    left: 50%;
    margin-left: -512px;
  }
}

</style>
