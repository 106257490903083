<template>
  <div class="rank-element"
       :class="{static: isStatic}" ref="ranking" @dblclick="animate">
    <div class="placement" :style="placementColor">
      <div class="rank">{{ placement }}</div>
      <RankElementStripes
          class="stripes"
          :colors="getStripeColors()"
      />
    </div>
    <div class="content" :style="rowColor">
      <div class="middle">
        <div class="name"> {{ name }} </div>
        <div class="delta fadeOutAnimation" ref="delta" v-if="delta > 0"> +{{ delta }} </div>
      </div>
      <RankElementCounter :count="amount" :type="type" @delta="deltaUpdate" :color="color" :text-color="textColor"/>
    </div>
  </div>
</template>

<script>
import RankElementStripes from "@/components/RankElementStripes";
import RankElementCounter from "@/components/RankElementCounter";
export default {
  name: 'RankElement',
  components: {RankElementCounter, RankElementStripes},
  props: ['id', 'placement', 'name', 'amount','type', 'color', 'textColor'],
  computed: {
    placementColor() {
      let style = {
        'backgroundColor': '#9b9b9b',
        'boxShadow': 'inset 0px 0px 13px 4px #626262'
      }
      if (this.placement === 1) style['backgroundColor'] = '#e1c322'
      if (this.placement === 2) style['backgroundColor'] = '#cccccc'
      if (this.placement === 3) style['backgroundColor'] = '#a8715d'
      if (this.placement === 1) style['boxShadow'] = 'inset 0px 0px 13px 4px #c1a302'
      if (this.placement === 2) style['boxShadow'] = 'inset 0px 0px 13px 4px #acacac'
      if (this.placement === 3) style['boxShadow'] = 'inset 0px 0px 13px 4px #88513d'
      return style
    },
    rowColor() {
      return {
        'backgroundColor': this.placement % 2 === 0 ? '#fff' : '#f5f5f5'
      }
    },
  },
  data() {
    return {
      delta: 0,
      isStatic: false
    }
  },
  methods: {
    getStripeColors() {
      if (this.placement === 1) return [
        '#8f7c15',
        '#af9c35',
        '#cfbc55'
      ]
      if (this.placement === 2) return [
        '#7b7b7b',
        '#9b9b9b',
        '#bbbbbb',
      ]
      if (this.placement === 3) return [
        '#78412d',
        '#98614d',
        '#b8816d'
      ]
      return [
        '#6d6d6d',
        '#8d8d8d',
        '#adadad'
      ]
    },
    deltaUpdate(delta) {
      this.delta = delta

      /*const elemDelta = this.$refs.delta
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        $(elemDelta).addClass('fadeOutAnimation')
      }, 500)
      // eslint-disable-next-line no-undef
      $(elemDelta).removeClass('fadeOutAnimation')*/
    },
    async animate() {
      await this.slideOutRight()
      await this.slideInLeft()
    },
    async slideOutRight() {
      return new Promise((resolve) => {
        const ranking = this.$refs.ranking
        // eslint-disable-next-line no-undef
        $(ranking).css("margin-left", '0px')
        // eslint-disable-next-line no-undef
        $(ranking).animate({"margin-left": '-100'}, {
          duration: 500,
          specialEasing: {
            width: "easeOutBounce",
            height: "easeOutBounce"
          },
          complete: function() {
            // eslint-disable-next-line no-undef
            $(ranking).animate({"margin-left": '1800'}, {
              duration: 1200,
              specialEasing: {
                width: "easeOutBounce",
                height: "easeOutBounce"
              },
              complete: resolve
            })
          }
        })
      })
    },
    async slideOutLeft() {
      return new Promise((resolve) => {
        const ranking = this.$refs.ranking
        // eslint-disable-next-line no-undef
        $(ranking).css("margin-left", '0px')
        // eslint-disable-next-line no-undef
        $(ranking).animate({"margin-left": '100'}, {
          duration: 500,
          specialEasing: {
            width: "easeOutBounce",
            height: "easeOutBounce"
          },
          complete: function() {
            // eslint-disable-next-line no-undef
            $(ranking).animate({"margin-left": '-1800'}, {
              duration: 750,
              specialEasing: {
                width: "easeOutBounce",
                height: "easeOutBounce"
              },
              complete: resolve
            })
          }
        })
      })
    },
    async slideInLeft() {
      return new Promise((resolve) => {
        const ranking = this.$refs.ranking
        // eslint-disable-next-line no-undef
        $(ranking).css("margin-left", '-1800px')
        // eslint-disable-next-line no-undef
        $(ranking).animate({"margin-left": '0px'}, {
          duration: 1700,
          specialEasing: {
            width: "easeOutBounce",
            height: "easeOutBounce"
          },
          complete: resolve
        })
      })
    },
    hide() {
      const ranking = this.$refs.ranking
      // eslint-disable-next-line no-undef
      $(ranking).css("margin-left", '-1800px')
    }
  },
  mounted() {
    console.log('I exist now: '+this.$props.placement)
    if (this.placement <= 3){
      this.isStatic = true
    }
  }
}
</script>
<style lang="scss">

.rank-element {
  width: 100%;
  height: 50px;
  margin: 15px 0px;
  display: flex;
  font-size: 2.0rem;
  box-shadow: 0px 0px 11px 0px #303030;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1920px;

  .placement {
    width: 120px;
    display: flex;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    font-weight: bolder;
    .rank {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
    }
    .stripes {
      height: 50px;
      width: 200px;
      position: absolute;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .middle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .name {
        margin-left: 40px;
      }
      .delta {
        margin-right: 10px;
        color: #31c700;
      }
    }
  }
}

.static {
  margin-left: 0px;
}

.fadeOutAnimation {
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 0s;
}

@keyframes fadeOut {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}

</style>
