<template>
  <img v-if="backgroundReady" class="bg" :src="background">
  <img v-else class="bg seidl">
  <div id="app">
    <div v-if="test" class="watermark">
      <img src="./assets/test-watermark.png" />
    </div>
    <template v-if="!valid">
      <RankHeader :title="'SEIDLWERTUNG'" :font="'Merienda'" :textColor="'#edd1a6'"/>
    </template>
    <template v-else>
      <RankHeader :title="title" :font="setting.font" :textColor="setting.text_color"/>
    </template>
    <template v-if="loading">
      Loading...
    </template>
    <template v-else>
      <template v-if="!valid">
        <EventInput ref="test" @codeEntered="validateCode"/>
      </template>
      <template v-if="valid && displaySelection">
        <DisplayInput ref="test" @displaySelected="(number) => { loadDisplaySettings(number) }" />
      </template>
      <template v-else>
        <div v-if="rankings && valid && !displaySelection" style="margin: 0 auto; width: 80%">
          <template v-for="(ranking, idx) in displayRankings" v-bind:key="idx">
            <RankElement
                v-if="idx < displayCount"
                :id="ranking.id"
                :placement="getPlacementBySlot(idx+1)"
                :name="ranking.name"
                :amount="ranking.amount"
                :ref="'ranking'+(idx+1)"
                :type="type"
                :color="setting.color"
                :text-color="setting.text_color"
            />
            <div v-if="idx+1 == 3" style="margin-bottom: 60px;"/>
          </template>
        </div>
        <RankFirework v-if="false" />
      </template>
    </template>
    <RankFooter v-if="footer" :image="footer" :eventId="this.eventId"/>
  </div>
</template>

<script>
import restService from './components/services/rest';
import RankElement from '/src/components/RankElement'
import RankHeader from "@/components/RankHeader";
import RankFooter from "@/components/RankFooter";
import RankFirework from "@/components/RankFirework";
import EventInput from "@/components/EventInput";
import DisplayInput from "@/components/DisplayInput";

const DYNAMIC_RANKINGS_PER_PAGE = 7
const STATIC_RANKINGS_PER_PAGE = 3
const AMOUNT_TOTAL = DYNAMIC_RANKINGS_PER_PAGE + STATIC_RANKINGS_PER_PAGE
const PAGINATION_CYCLE_DIVIDER = 10
const ENDPOINT = process.env.VUE_APP_BACKEND_URL + '/api'

export default {
  name: 'App',
  components: {
    EventInput,
    DisplayInput,
    RankFirework,
    RankHeader,
    RankFooter,
    RankElement,
  },
  data() {
    return {
      rankings: [],
      displayRankings: [],
      displayCount: 0,
      valid: false,
      loading: false,
      eventId: NaN,
      pageNr: 0,
      pageToggle: false,
      pageTicks: 0,
      title: 'SEIDLWERTUNG',
      type: 'SEIDL',
      test: false,
      background: undefined,
      footer: undefined,
      displays: [],
      displaySelection: false,
      setting: {},
      defaultSetting: {
        background: 'default/background.png',
        footer: 'default/footer.png',
        text_color: '#895b25',
        color: '#f8be7b',
        font: 'Merienda'
      },
      backgroundReady: false,
    }
  },
  methods: {
    appMode() {
        return this.type.toLowerCase()
    },
    loadFromStorage() {
      this.eventId = parseInt((this.getCookie('eventId')))
      this.displayNr = parseInt((this.getCookie('displayNr')))
    },
    validateCode(code) {
      this.loading = true
      fetch(`${ENDPOINT}/event/code/${code}`)
          .then(response => response.json())
          .then(data => {
            this.loading = false
            if (!data['error']) {
              this.eventId = data['id']
              this.handleDisplays()
              this.fetchEventTest()
              this.getTitle()
              this.appMode()
              this.setCookie('eventId', data['id'])
              this.valid = true;
            } else {
              console.error(data['message'])
            }
          }).catch((error) => {
        console.error('Error:', error)
      })
      // eslint-disable-next-line no-undef
      $(this.$refs.inputField).addClass('fadeOutAnimation')
    },
    async updateTeams() {
      await this.getTitle()
      if (!this.valid) return
      //this.ready()
      const teams = await this.fetchTeams()


      teams.sort((a, b) => {
        if (this.getTeamCount(a) === this.getTeamCount(b)){
          let da
          let db
          if (a.entries[a.entries.length - 1] && b.entries[b.entries.length - 1]){
            da = new Date(a.entries[a.entries.length - 1].created)
            db = new Date(b.entries[b.entries.length - 1].created)
          } else {
            da = new Date(a.created)
            db = new Date(b.created)
          }
          return db - da
        }else{
          return this.getTeamCount(b) - this.getTeamCount(a)
        }
      })

      const teamIdentifier = teams.map(team => team.id).join('')
      const oldTeamIdentifier = this.rankings.map(ranking => ranking.id).join('')

      if (teamIdentifier.length !== oldTeamIdentifier.length) {
        await this.reloadTeams(teams)
      } else {
        let newRankings = []
        let changedIndices = []

        teams.forEach(team => {
          let date, teamCreation = null
          if (team.entries[team.entries.length - 1]){
            date = new Date(team.entries[team.entries.length - 1].created)
          } else {
            date = new Date(0)
          }
          teamCreation = new Date(team.created)

          newRankings.push({
            id: team.id,
            name: team.title,
            amount: this.getTeamCount(team),
            date,
            teamCreation,
          })

        })
        for (let placement = 1; placement <= newRankings.length; placement++){

          const index = placement-1

          if(newRankings[index].id !== this.rankings[index].id){
            if (this.isActive(placement)){
              const elem = this.getElementByPlacement(placement)
              elem.slideOutRight()
              await this.sleep(100)
              changedIndices.push(placement)
            }

            this.rankings[index].id = newRankings[index].id
            this.rankings[index].name = newRankings[index].name
            this.rankings[index].amount = newRankings[index].amount

          } else {
            this.rankings[index].name = newRankings[index].name
            this.rankings[index].amount = newRankings[index].amount
          }
        }

        await this.sleep(1800)

        await this.updateDisplayRankingValues()

        await this.asyncForEach(changedIndices, async changedIndex => {
          const elem = this.getElementByPlacement(changedIndex)
          elem.slideInLeft()
          await this.sleep(100)
        })

        await this.sleep(1800)

        if (this.rankings.length > (AMOUNT_TOTAL) && ++this.pageTicks % PAGINATION_CYCLE_DIVIDER == 0){
          for (let slot = 4; slot <= 10; slot++) {
            const placement = this.getPlacementBySlot(slot)
            if (!this.isActive(placement)) continue
            const elem = this.getElementByPlacement(placement)
            if (elem) {
              elem.slideOutRight()
              await this.sleep(100)
            }
          }

          await this.sleep(1500)

          this.pageNr++
          this.pageNr %= 1 + Math.floor((this.rankings.length - STATIC_RANKINGS_PER_PAGE) / DYNAMIC_RANKINGS_PER_PAGE)
          await this.updateDisplayRankings()
        }
      }
    },
    async reloadTeams(teams) {
      this.rankings = []

      let tmpTeams = []

      await this.asyncForEach(teams, async team => {
        let amount = this.getTeamCount(team)
        let date, teamCreation
        if (team.entries.length > 0){
          date = new Date(team.entries[team.entries.length - 1].created)
        } else {
          date = new Date(0)
        }

        teamCreation = new Date(team.created)

        tmpTeams.push({
          id: team.id,
          name: team.title,
          amount,
          date,
          teamCreation,
        })

        tmpTeams.sort((a, b) => {
          if (a.amount === b.amount){
            if(a.date === b.date){
              return b.teamCreation  - a.teamCreation
            }else{
              return b.date - a.date
            }
          }else{
            return b.amount - a.amount;
          }
        })
      })
      await this.asyncForEach(tmpTeams, async team => {
        this.rankings.push(team)
      })

      for (let n = 0; n < 10; n++) {
        this.displayRankings.push({
          id: -1,
          name: '',
          amount: 0
        })
      }

      await this.updateDisplayRankings()
    },
    getTeamCount(team) {
      let amount = 0

      team.entries.forEach(entry => {
        amount += entry.count
      })

      return amount
    },
    getSlot(placement) {
      if (placement <= STATIC_RANKINGS_PER_PAGE){
        return placement
      } else {
        placement -= 4
        return (placement % DYNAMIC_RANKINGS_PER_PAGE) + 4
      }
    },
    getElementByPlacement(placement) {
      if (this.isActive(placement)){
        const elems = this.$refs['ranking'+this.getSlot(placement)]
        return elems ? elems[0] : undefined
      }
    },
    getPlacementBySlot(slot) {
      if (slot <= STATIC_RANKINGS_PER_PAGE) {
        return slot
      }
      return slot + this.pageNr * DYNAMIC_RANKINGS_PER_PAGE
    },
    getRankingBySlot(slot) {
      if (slot <= STATIC_RANKINGS_PER_PAGE) {
        return this.rankings[slot-1]
      } else {
        let placement = this.getPlacementBySlot(slot)
        if ((placement - 1) < this.rankings.length) {
          if (this.isActive(placement)) {
            return this.rankings[placement - 1]
          }
        }
      }
    },
    getCurrentSlotCount() {
      return Math.min(10, this.rankings.length - this.pageNr * DYNAMIC_RANKINGS_PER_PAGE)
    },
    isActive(placement) {
      if (placement <= STATIC_RANKINGS_PER_PAGE){
        return true
      } else {
        placement -= STATIC_RANKINGS_PER_PAGE

        let lowerBound = this.pageNr * DYNAMIC_RANKINGS_PER_PAGE
        let upperBound = (this.pageNr + 1) * DYNAMIC_RANKINGS_PER_PAGE

        return placement > lowerBound && placement <= upperBound
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    },
    async fetchTeams() {
      return new Promise((resolve, reject) => {
        fetch(`${ENDPOINT}/event/${this.eventId}/teams`)
            .then(data => data.json())
            .then(data => {
              if (!data['error']) {
                resolve(data)
              } else {
                reject(data)
              }
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    setCookie(name, value, days = 100) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    async updateDisplayRankingValues() {
      for (let slot = 1; slot <= this.displayCount ; slot++) {
        let ranking = this.getRankingBySlot(slot)
        this.displayRankings[slot-1].id = ranking.id
        this.displayRankings[slot-1].name = ranking.name
        this.displayRankings[slot-1].amount = ranking.amount
      }
    },
    async updateDisplayRankings() {
      this.displayCount = STATIC_RANKINGS_PER_PAGE

      let context = this

      setTimeout(async function () {
        context.displayCount = context.getCurrentSlotCount()
        await context.updateDisplayRankingValues()

        for (let slot = 4; slot <= context.displayCount ; slot++) {
          await context.sleep(100)
          setTimeout(function () {
            const placement = context.getPlacementBySlot(slot)
            const elem = context.getElementByPlacement(placement)
            console.log(elem)
            if (elem) {
              elem.slideInLeft()
            }
          }, 100)
        }
      }, 100)
    },
    async getTitle() {
      const type = await this.fetchType()

      if (!type) return

      if (type.join("") === 'SEIDL') {
        this.title = 'SEIDLWERTUNG';
        this.type = 'seidl';
      } else if ( type.join("") === 'SCHNOPS') {
        this.title = 'SCHNOPSWERTUNG'
        this.type = 'schnops';
      } else if ( type.join("") === 'SPRITZER') {
        this.title = 'SPRITZERWERTUNG'
        this.type = 'spritzer';
      } else if ( type.join("") === 'BAR') {
        this.title = 'BARWERTUNG'
        this.type = 'bar';
      } else if ( type.join("") === 'METER') {
        this.title = 'METERWERTUNG'
        this.type = 'meter';
      } else if ( type.join("") === 'FOOD') {
        this.title = 'ESSENSWERTUNG'
        this.type = 'food';
      } else {
        this.title = 'UNKNOWN'
        this.type = 'unknown';
      }
    },
    async fetchType() {
      if (isNaN(this.eventId) || this.eventId === -1) return
      return new Promise((resolve, reject) => {
        fetch(`${ENDPOINT}/event/${this.eventId}/type`)
            .then(data => data.json())
            .then(data => {
              if (!data['error']) {
                resolve(data)
              } else {
                reject(data)
              }
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    async fetchEventTest() {
      if (isNaN(this.eventId)) return

      let eventTest = await restService.eventIsTest(this.eventId);
      this.test = eventTest.test === 1;
    },
    async loadDisplaySettings(number) {

      this.loadFromStorage()

      if (isNaN(this.eventId) || this.eventId === -1) {
        return
      }

      const displays = []

      try {
        displays.push(...await restService.loadDisplaysByEvent(this.eventId))
      } catch (err) {
        this.setting = this.defaultSetting
        this.backgroundReady = true
      }

      if (displays === []) {
        this.setting = this.defaultSetting
      } else if (displays.length === 1) {
        this.setting = displays[0]
      } else if (displays.length > 1){
        this.setting = displays.filter((display) => {
          return display.number === parseInt(number)
        })[0]
        if (!this.setting) {
          this.setting = this.defaultSetting
        }
      } else {
        this.setting = this.defaultSetting
      }

      if (this.setting) {
        this.background = process.env.VUE_APP_BACKEND_URL + '/images/displays/' + this.setting.background
        this.footer = process.env.VUE_APP_BACKEND_URL + '/images/displays/' + this.setting.footer
      }
      this.displaySelection = false
      this.backgroundReady = true
    },
    async handleDisplays() {
      if (isNaN(this.eventId) || this.eventId === -1) return

      this.displays = await restService.loadDisplaysByEvent(this.eventId)

      if (this.displays.length > 1) {
        this.displaySelection = true
      } else if (this.displays.length === 1) {
        await this.loadDisplaySettings(this.displays[0].number)
      }
    },
  },
  async mounted() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    if(code) {
      this.validateCode(code)
    }

    if (this.getCookie('displayNr')) {
      await this.loadDisplaySettings(parseInt(this.getCookie('displayNr')))
    }

    if (this.getCookie('eventId')) {
      this.eventId = parseInt((this.getCookie('eventId')))
      this.valid = true
    }

    if (this.eventId === -1) {
      this.setting = this.defaultSetting
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await this.updateTeams()
      await this.fetchEventTest()
      await this.sleep(3000)
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
  color: #2c3e50;
  scroll-behavior: smooth;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 1920px;
  max-height: 1080px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.seidl{
  background-image: url("./assets/seidlwertung.jpg");
}

.schnops{
  background-image: url("./assets/schnopswertung.jpg");
}

.schnops{
  background-image: url("./assets/schnopswertung.jpg");
}

.bar {
  background-image: url("./assets/barwertung_disco.png");
}

.bar_stodl{
  background-image: url("./assets/barwertung_stodl.png");
}

.watermark{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img.bg {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  img.bg {
    left: 50%;
    margin-left: -512px;
  }
}

</style>
