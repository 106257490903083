<template>
  <div class="counter" :style="{ 'box-shadow' : getShadow(color), 'background-color' : color }">
    <img v-if=" type === 'SEIDL'" class="drink" ref="drink" src="../assets/beer.png" />
    <img v-if=" type === 'SCHNOPS'" class="drink" ref="drink" src="../assets/other.png" />
    <div class="amount" ref="amount" :style="{ 'color' : textColor }">{{ amount }}</div>
  </div>
</template>

<script>
export default {
  name: "RankElementCounter",
  props: ['count','type', 'color', 'textColor'],
  methods: {
    appMode() {
      return process.env.VUE_APP_MODE
    },
    animate() {
      const elemDrink = this.$refs.drink
      const elemAmount = this.$refs.amount
      const context = this

      this.animating = true

      // eslint-disable-next-line no-undef
      $(elemDrink).addClass('wiggle_on')
      // eslint-disable-next-line no-undef
      $(elemAmount).animate({fontSize: '2.5rem'}, 500, function () {
        context.$emit('delta', context.$props.count - context.amount)
        context.amount = context.$props.count
        // eslint-disable-next-line no-undef
        $(elemAmount).animate({fontSize: '2.0rem'}, 500, function () {
          // eslint-disable-next-line no-undef
          $(elemDrink).removeClass('wiggle_on')
          context.animating = false
        })
      })
    },
    getShadow(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const r = Math.trunc(parseInt(result[1], 16) * 0.75)
      const g = Math.trunc(parseInt(result[2], 16) * 0.75)
      const b = Math.trunc(parseInt(result[3], 16) * 0.75)
      return "inset 0px 0px 8px 5px rgb(" + this.componentToHex(r) + ", " + this.componentToHex(g) + ", " + this.componentToHex(b) + ")";
    },
    componentToHex(c) {
      const hex = c.toString();
      return hex.length == 1 ? "0" + hex : hex;
    }
  },
  data() {
    return {
      amount: 0,
      animating: false,
      animationChecker: undefined
    }
  },
  mounted() {
    const context = this

    this.amount = this.$props.count

    this.animationChecker = setInterval(function () {
      if (!context.animating && context.amount !== context.$props.count) {
        context.animate()
      }
    }, 100)
  },
  unmounted() {
    clearInterval(this.animationChecker)
  }
}
</script>

<style scoped lang="scss">
.counter {
  width: 150px;
  display: flex;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f8be7b;
  box-shadow: inset 0px 0px 8px 5px #c99b65;
  box-sizing: border-box;
  height: 100%;
  .drink {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
  .amount {
    width: 100%;
    color: #895b25;
    font-weight: bold;
  }

  .wiggle_on {
    display: inline-block;
    animation: wiggle 1.0s;
  }

  @keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(25deg); }
    75% { transform: rotate(-25deg); }
    100% { transform: rotate(0deg); }
  }

  .spin_on {
    display: inline-block;
    animation: spin 4.5s infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    80% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
