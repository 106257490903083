import { createApp } from 'vue'
import App from './App.vue'

let app = createApp(App)
app.mount('#app')

let keysPressed = {}

function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

document.addEventListener('keydown', function(e){
    keysPressed[e.key] = true;
})

document.addEventListener('keyup', (event) => {
    delete keysPressed[event.key];
})

document.addEventListener('keydown', (event) => {
    keysPressed[event.key] = true;

    if (keysPressed['Control'] && event.key == 'l') {
        eraseCookie('eventId')
        location.reload()
    }
});
