<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Merienda&display=swap" rel="stylesheet">
  <div class="headline" :style="{ 'font-family' : getFont(), 'text-shadow' : getShadowColor(), 'color' : textColor }">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "RankHeader",
  props: ['title', 'font', 'textColor'],
  methods: {
    getFont() {
      if (this.font) {
        return this.font.replace(/\s/g, '')
      } else {
        return 'Meridena'
      }
    },
    getShadowColor() {
      if (!this.textColor) {
        return "3px 10px #523416"
      }
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.textColor);
      const r = Math.trunc(parseInt(result[1], 16) * 0.33)
      const g = Math.trunc(parseInt(result[2], 16) * 0.33)
      const b = Math.trunc(parseInt(result[3], 16) * 0.33)

      console.log("3px 10px rgb(" + this.componentToHex(r) + ", " + this.componentToHex(g) + ", " + this.componentToHex(b) + ")")
      return "3px 10px rgb(" + this.componentToHex(r) + ", " + this.componentToHex(g) + ", " + this.componentToHex(b) + ")"
    },
    componentToHex(c) {
      const hex = c.toString();
      return hex.length == 1 ? "0" + hex : hex;
    }
  }
}
</script>

<style scoped>
.headline {
  margin-top: 30px;
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: 'Merienda', cursive;
  font-size: 5.5rem;
  font-weight: lighter;
  color: #edd1a6;
  text-shadow: 3px 10px #523416;
  background-color: #1a141477;
}
</style>
