
const ENDPOINT = process.env.VUE_APP_BACKEND_URL + '/api'

const loadEventSponsors = async (eventId) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(`${ENDPOINT}/event/${eventId}/sponsors`, requestOptions)
            .then((response) => response.json())
            .then((payload) => {
                if (payload.error === true) {
                    reject(payload)
                } else {
                    resolve(payload)
                }
            })
            .catch((err) => reject(err))
    })

const loadFooters = async (eventId) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(`${ENDPOINT}/event/${eventId}/footers`, requestOptions)
            .then((response) => response.json())
            .then((payload) => {
                if (payload.error === true) {
                    reject(payload)
                } else {
                    resolve(payload)
                }
            })
            .catch((err) => reject(err))
    })

const eventIsTest = async (eventId) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(`${ENDPOINT}/event/${eventId}/test`, requestOptions)
            .then((response) => response.json())
            .then((payload) => {
                if (payload.error === true) {
                    reject(payload)
                } else {
                    resolve(payload)
                }
            })
            .catch((err) => reject(err))
    })

// eslint-disable-next-line no-async-promise-executor
const loadDisplaysByEvent = async (eventId) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(`${ENDPOINT}/event/${eventId}/displays`, requestOptions)
            .then((response) => response.json())
            .then((payload) => {
                if (payload.error === true) {
                    reject(payload)
                } else {
                    resolve(payload)
                }
            })
            .catch((err) => reject(err))
    })

export default {
    loadEventSponsors,
    loadFooters,
    eventIsTest,
    loadDisplaysByEvent
}
