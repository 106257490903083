<template>
  <div class="event-input" v-on:keyup.enter="selectDisplay">
    <input ref="number" v-model="displayNumber" placeholder="Display Nr.">
    <button @click="selectDisplay">WEITER</button>
  </div>
</template>

<script>
export default {
  name: "DisplayInput",
  methods: {
    selectDisplay() {
      this.setCookie('displayNr', this.displayNumber)
      this.$emit('displaySelected', this.displayNumber)
    },
    setCookie(name, value, days = 100) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
  },
  data() {
    return {
      displayNumber: ''
    }
  },
  mounted() {
    this.$refs.number.focus()
  }
}
</script>

<style scoped lang="scss">
.event-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    display: block;
    margin-top: 176px;
    height: 170px;
    width: 600px;
    text-align: center;
    font-family: "Courier New";
    font-size: 95px;
    box-shadow: inset 0px 0px 10px 6px rgb(108 80 44);
    background-color: #dab968;
    border: 0px solid;
    outline: none;
    background-image: url('../assets/cut.jpg')
  }

  button {
    display: block;
    margin-top: 80px;
    height: 100px;
    width: 400px;
    text-align: center;
    font-family: "Courier New";
    font-size: 2.5rem;
    font-weight: bold;
    outline: none;
    box-shadow: 5px 3px 11px #464646;
    background-color: #ffd499;
  }
}

</style>