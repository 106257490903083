<template>
  <div class="event-input" v-on:keyup.enter="checkEventCode">
    <input ref="code" v-model="eventCode" placeholder="**********">
    <button @click="checkEventCode">WEITER</button>
  </div>
</template>

<script>
export default {
  name: "EventInput",
  methods: {
    checkEventCode() {
      this.$emit('codeEntered', this.eventCode)
    },
  },
  data() {
    return {
      eventCode: ''
    }
  },
  mounted() {
    this.$refs.code.focus()
  }
}
</script>

<style scoped lang="scss">
.event-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    display: block;
    margin-top: 176px;
    height: 170px;
    width: 600px;
    text-align: center;
    font-family: "Courier New";
    font-size: 95px;
    box-shadow: inset 0px 0px 10px 6px rgb(108 80 44);
    background-color: #dab968;
    border: 0px solid;
    outline: none;
    background-image: url('../assets/cut.jpg')
  }

  button {
    display: block;
    margin-top: 80px;
    height: 100px;
    width: 400px;
    text-align: center;
    font-family: "Courier New";
    font-size: 2.5rem;
    font-weight: bold;
    outline: none;
    box-shadow: 5px 3px 11px #464646;
    background-color: #ffd499;
  }
}

</style>